import styles from './ItemMainInfo.module.scss';
import toCurrency from 'shared/lib/currencyFormater';
import roundPrice from 'shared/lib/roundPrice';
import renderBonusMessage from './lib/renderBonusMessage';

export default function ItemMainInfo({
  price,
  quantity,
  is_active,
  red_price,
  discount_price,
  discount,
  is_manelyak,
}) {
  const bonus = ((discount_price || price) / 100) * 3;
  const bonusSumm = toCurrency.format(roundPrice(bonus));
  const bonusMessage = renderBonusMessage(is_manelyak, bonusSumm);

  const mainPrice = toCurrency.format(roundPrice(discount_price || price));
  const oldPrice = toCurrency.format(roundPrice(price));

  if (!quantity && !is_active) {
    return null;
  }

  return (
    <div className={styles.itemInfo}>
      {!red_price && bonusMessage}
      {is_manelyak && (
        <p className={styles.isManelyakMessage}>
          Данный товар доступен только при самовывозе с филиала
        </p>
      )}
      <div className={styles.pricesContainer}>
        <div className={styles.prices}>
          <h3 className={styles.mainPricePrimary}>{mainPrice} руб.</h3>
          {discount > 0 && <p className={styles.oldPrice}>{oldPrice} руб.</p>}
        </div>
      </div>
    </div>
  );
}
