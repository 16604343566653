import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './ItemPhotoSlider.module.scss';
import './SwiperStyles.scss';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from 'swiper/modules';

import { useImageModal } from 'entities/Modal/ui/components/ImageModal/lib/store';
import { useLoginModal } from 'entities/Modal/ui/components/LoginModal/lib/store';
import { useUser } from 'store/user';

import { GoHeart } from 'react-icons/go';
import { GoHeartFill } from 'react-icons/go';
import { WarningBadge } from 'shared/ui/WarningBadge';
import { VideoCardSliderSmall } from 'shared/ui/VideoCardSliderSmall';
import { VideoCardSlider } from 'shared/ui/VideoCardSlider';
import { ImageModal } from 'entities/Modal/ui/components/ImageModal';
import { useFavorite } from 'store/favorite';

export default function ItemPhotoSlider({
  id,
  title,
  quantity,
  is_active,
  discount,
  images,
  isInFavorite,
  video,
  setIsVideoPlayerOpen,
}) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  // Состояние модалки с открытием изображения и его индекс
  const setActiveImgModal = useImageModal((state) => state.setActiveModal);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // Добавление в избранное
  const isAuth = useUser((state) => state.isAuth);
  const [inFavorite, setInFavorite] = useState(() => isInFavorite);
  const setActiveLoginModal = useLoginModal((state) => state.setActiveModal);
  const { addToFavorite, removeFromFavorite } = useFavorite((state) => ({
    addToFavorite: state.addToFavorite,
    removeFromFavorite: state.removeFromFavorite,
  }));

  const onAddFavorite = () => {
    addToFavorite(id);
    setInFavorite(true);
  };

  const onRemoveFavorite = () => {
    removeFromFavorite(id);
    setInFavorite(false);
  };

  // Вызов модалки Вход в ЛК
  const onShowEnterModal = () => {
    setActiveLoginModal(true);
  };

  // Открываем фото на весь экран
  const onOpenImageModal = (index) => {
    setActiveImgModal(true);
    setCurrentImageIndex(index);
  };

  return (
    <>
      <div className={styles.itemPhotoes}>
        <div className={styles.itemMainPhotoBox}>
          {quantity && is_active ? (
            <>
              {discount > 0 && (
                <span className={styles.discountBage}>-{discount}%</span>
              )}
            </>
          ) : (
            <div className={styles.badge}>
              <WarningBadge text="Под заказ" />
            </div>
          )}

          {inFavorite ? (
            <GoHeartFill
              className={styles.favoriteIcon}
              onClick={onRemoveFavorite}
            />
          ) : (
            <GoHeart
              className={styles.favoriteIcon}
              onClick={isAuth ? onAddFavorite : onShowEnterModal}
            />
          )}

          <Swiper
            modules={[Thumbs]}
            thumbs={{ swiper: thumbsSwiper }}
            loop={true}
            grabCursor={true}
            className="mySwiper"
          >
            {images.length === 0 ? (
              <SwiperSlide>
                <img
                  className={styles.itemMainPhoto}
                  src={`/img/no-image.webp`}
                  alt="no-image"
                />
              </SwiperSlide>
            ) : (
              <>
                {images.map((image, index) => (
                  <SwiperSlide key={index}>
                    <img
                      className={styles.itemMainPhoto}
                      onClick={() => onOpenImageModal(index)}
                      src={image.image}
                      onError={(e) => (e.target.src = '/img/no-image.webp')}
                      alt={title}
                    />
                  </SwiperSlide>
                ))}
                {video && (
                  <SwiperSlide>
                    <VideoCardSlider
                      img={images[0].image}
                      title={title}
                      setIsVideoPlayerOpen={setIsVideoPlayerOpen}
                    />
                  </SwiperSlide>
                )}
              </>
            )}
          </Swiper>
        </div>
        {/* Если фото только одно и нет ссылки на видео, то блок скрываем */}
        {images.length === 1 && !video ? null : (
          <div className={styles.itemSecondaryPhotoes}>
            <Swiper
              modules={[Thumbs]}
              onSwiper={setThumbsSwiper}
              slidesPerView="auto"
              slidesPerGroup={1}
              spaceBetween={16}
              breakpoints={{
                768: {
                  spaceBetween: 24,
                },
              }}
              className="mySwiperSmall"
            >
              {images.map((image) => (
                <SwiperSlide key={image.image}>
                  <img
                    src={image.image}
                    alt={title}
                    onError={(e) => (e.target.src = '/img/no-image.webp')}
                  />
                </SwiperSlide>
              ))}

              {/* Если фото только одно и нет ссылки, то блок скрываем */}
              {video && (
                <SwiperSlide>
                  <VideoCardSliderSmall
                    img={images[0].image}
                    title={title}
                    setIsVideoPlayerOpen={setIsVideoPlayerOpen}
                  />
                </SwiperSlide>
              )}
            </Swiper>
          </div>
        )}
      </div>
      {images.length !== 0 &&
        createPortal(
          <ImageModal>
            <img
              src={images[currentImageIndex].image}
              alt={title}
              onError={(e) => (e.target.src = '/img/no-image.webp')}
            />
          </ImageModal>,
          document.body,
        )}
    </>
  );
}
